<template>
  <v-container>
    <v-row justify="center">
      <v-col md="6" sm="9">
        <v-card class="mx-auto">
          <v-form v-model="valid" ref="form" @submit.prevent="submit">
            <v-card-text class="pb-0">
              <v-textarea
                v-model.trim="detail"
                placeholder="รายการสั่งซื้อ"
                id="detail"
                clear-icon="mdi-close-circle"
                clearable
                auto-grow
                rows="6"
                :rules="[() =>
                          (!!detail) ||
                          'กรุณากรอกให้ถูกต้อง']"
              ></v-textarea>
            </v-card-text>
            <v-card-actions class="pb-3 pt-2">
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn type="submit" :disabled="!valid" color="success"
                >ส่ง <v-icon class="ml-2">mdi-send</v-icon></v-btn
              >
                <v-spacer></v-spacer>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="6" sm="9" class="pb-0">
        <v-chip
        small
        color="pink"
        label
        text-color="white"
        >
        <v-icon small left>
            mdi-label
        </v-icon>
        <b> LIVE {{totalrecords}} รายการ</b> 
        </v-chip>
      </v-col>
    </v-row>

    <v-row v-for="(item, index) in items" :key="index"  justify="center">
      <v-col md="6" sm="9" class="pb-0">
        <v-card class="mx-auto" color="yellow lighten-5">
          <v-card-text class="pa-2 grey--text text--darken-4">
              <div class="mydatanewline" v-html="item.detail"></div>
          </v-card-text>
          <v-card-actions class="pt-1 pb-1 body-2 grey--text text--darken-2 yellow lighten-4">
            <v-chip x-small label color="green" class="pl-1 pr-1" dark><b>{{item.id}}</b></v-chip>
            <v-icon size="18" class="mr-2 ml-1" @click="doDelete(item.id)">mdi-trash-can-outline</v-icon> {{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}  
            <v-icon size="18" class="mr-1 ml-2">mdi-face-outline</v-icon> {{ createdBy(item.ref_user_id) }}
            <v-spacer></v-spacer>
            <v-icon size="18" @click="doEdit(item)">mdi-clipboard-edit-outline</v-icon>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

     <v-snackbar
      v-model="snackbar.active"
      top
      right
      app
      :color="snackbar.color"
      dark
      :timeout="1000"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.active = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


  <!-- edit detail -->
    <v-dialog v-model="dialogEditDetail" width="450">
       <v-card>
                  <v-form ref="formedit" @submit.prevent="submitedit">
                    <v-card-subtitle>แก้ไข # {{detailEditId}}</v-card-subtitle>
                    <v-card-text class="pb-0">
                      <v-textarea
                        v-model.trim="detailEdit"
                        placeholder="รายการสั่งซื้อ"
                        id="detailEdit"
                        auto-grow
                        rows="6"
                        :rules="[() =>
                                  (!!detailEdit) ||
                                  'กรุณากรอกให้ถูกต้อง']"
                      ></v-textarea>
                    </v-card-text>
                    <v-card-actions class="pb-3 pt-2">
                      <v-btn type="submit" color="primary"
                        >ยืนยันแก้ไข <v-icon class="ml-2">mdi-send</v-icon></v-btn
                      >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-form>
        </v-card>
    </v-dialog>
    <!-- end detail -->

  </v-container>
</template>

<script>
import api, {LIVEORDERSTATUS} from "@/services/api";
import _ from 'lodash';

export default {
  name: "liveOrder",
  data() {
    return {
      dialogEditDetail: false,
      detailEdit: '',
      detailEditId: '',
      valid: true,
      detail: "",
      snackbar: {active: false, text: '', color: ''},
      items: [],
      users: []
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("detail", this.detail);
        formData.append("ref_user_id", this.$store.getters["userid"]);
        formData.append("status", LIVEORDERSTATUS.PENDING);
        let {data} = await api.addLiveOrder(formData);
        if (data.result == 'ok') {
            this.snackbar = { active: true, text: 'เพิ่มรายการสำเร็จ', color: 'blue darken-2' }
            this.$refs.form.reset();
            this.getData();
        } else {
            this.snackbar = { color: 'red darken-2', active: true, text: 'กรุณาลองใหม่อีกครั้ง' }
        }
      }
    },
    async submitedit() {
      if (this.detailEdit) {
          let formData = new FormData();
          formData.append("detail", this.detailEdit);
          formData.append("id", this.detailEditId);
          let result = await api.updateLiveOrderEditDetail(formData);
          if (result.data.result == 'ok') {
              this.snackbar = { active: true, text: 'แก้ไขข้อมูลสำเร็จ', color: 'blue darken-2' }
              this.detailEdit = '';
              this.detailEditId = '';
              this.dialogEditDetail = false;
              this.getData();
          } else {
              this.snackbar = { color: 'red darken-2', active: true, text: 'กรุณาลองใหม่อีกครั้ง' }
          }
      }
    },
    doEdit(item) {
      this.dialogEditDetail = true
      this.detailEdit = item.detail;
      this.detailEditId = item.id;
    },
    async getData() {
        let result = await api.getLiveOrders();
        this.items = result.data
    },
    async doDelete(id) {
        if (confirm('[ ' + id + ' ] คุณต้องการลบรายการนี้ ใช่หรือไม่')) {
            let result = await api.deleteLiveOrder(id)
            if (result.data.result == 'ok') {
                this.snackbar = { active: true, text: 'ลบข้อมูลสำเร็จ', color: 'blue darken-2' }
                this.getData();
            } else {
                this.snackbar = { color: 'red darken-2', active: true, text: 'กรุณาลองใหม่อีกครั้ง' }
            }
        }
    },
    async getUsers() {
        let result = await api.getUsers(1, 100, '');
        this.users = result.data.rows;
    },
    createdBy(user_id) {
        let user = _.find(this.users, {id: user_id});
        return user ? user.username : ''
    }
  },
  mounted() {
      this.getData();
      this.getUsers();
  },
  computed: {
      totalrecords() {
          return this.items.length
      }
  },
  beforeDestroy: function() {
    delete this.items;
    delete this.users;
  }
};
</script>

<style scoped>
.mydatanewline {
  white-space: pre-wrap; 
  word-wrap: break-word;
}
</style>
